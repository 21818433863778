import "./Benefits.scss"

function Benefits() {
  return (
    <div className="container-benefits">
      <div className="content-benefits container">
        <div className="introducer-benefits">
          <div className="bg-introducer"></div>
          <h3 className="title" id="benefits">
            beneficíos para o seu bumbum:
          </h3>
          <p className="benefits-info">
            o match perfeito que potencializa a nutrição do corpo deixando-o
            macio, delicioso e desavergonhado como todo corpo deve ser.
          </p>
        </div>

        <div className="content-list">
          <div className="bg-square-list"></div>
          <div className="list-item">
            <img
              className="square-item"
              src="/icons/benefits/list-item.png"
              alt="item da lista"
            />
            <span className="text-item">bumbum lisinho e sem manchas</span>
          </div>
          <div className="list-item">
            <img
              className="square-item"
              src="/icons/benefits/list-item.png"
              alt="item da lista"
            />
            <span className="text-item">sua pele - limpa e hidrata</span>
          </div>
          <div className="list-item">
            <img
              className="square-item"
              src="/icons/benefits/list-item.png"
              alt="item da lista"
            />
            <span className="text-item">amenize estrias e cicatrizes</span>
          </div>
          <div className="list-item">
            <img
              className="square-item"
              src="/icons/benefits/list-item.png"
              alt="item da lista"
            />
            <span className="text-item">bye-bye às celulites</span>
          </div>
          <div className="list-item">
            <img
              className="square-item"
              src="/icons/benefits/list-item.png"
              alt="item da lista"
            />
            <span className="text-item">
              previno e trato o ressecamento da pele
            </span>
          </div>
          <div className="list-item">
            <img
              className="square-item"
              src="/icons/benefits/list-item.png"
              alt="item da lista"
            />
            <span className="text-item">
              potencializa a nutrição do seu corpo
            </span>
          </div>
        </div>

        <div className="content-more">
          <div className="bg-rectangulo-list"></div>
          <h3 className="title">e mais:</h3>

          <div className="content-list-more">
            <div className="bg-rectangulo-list-blue"></div>
            <div className="item-1">
              <div className="item-content">
                <img
                  className="square-item"
                  src="/icons/benefits/list-item.png"
                  alt="item da lista"
                />
                <span className="text-item">
                  previne a estria da gravidez e não tem contra indicação mesmo
                  no período de gestação
                </span>
              </div>
            </div>
            <div className="item-2">
              <div className="item-content">
                <img
                  className="square-item"
                  src="/icons/benefits/list-item.png"
                  alt="item da lista"
                />
                <span className="text-item">
                  melhora a absorção de maquiagem e outros cosméticos em até 50%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Benefits
