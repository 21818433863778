import Button from "components/Button"

import "./ButtonBuy.scss"

function ButtonBuy() {
  return (
    <div className="container-button">
      <div className="line"></div>
      <Button text={"comprar por R$ 116"} />
    </div>
  )
}

export default ButtonBuy
