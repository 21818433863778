import "./Ingredients.scss"

function Ingredients() {
  return (
    <div className="container-ingredients" id="ingredients">
      <div className="content container">
        <div className="content-title">
          <div className="bg-title"></div>
          <h3 className="title">ingredientes</h3>
        </div>

        <div className="content-info">
          <div className="info">
            <div className="texts">
              <div className="bg-info"></div>
              <p className="text">
                <strong>amêndoas: </strong>com poderosa ação antioxidante, as
                amêndoas nutrem a pele, previne o envelhecimento e melhora a
                elasticidade da pele. Além de serem anti-inflamatórias e
                calmante.
              </p>
              <p className="text">
                <strong>óleo de coco: </strong>com propriedades anti-bacterianas
                e anti-ressecamento, o óleo de coco promove maciez e hidratação
                em qualquer momento do dia.
              </p>
              <p className="text">
                <strong>cenoura: </strong>além de aumentar a imunidade da pele
                através das vitaminas A e C, os antioxidantes da cenoura
                combatem a pele seca e tratam inflamações como dermatite e
                eczema.
              </p>
              <p className="text">
                <strong>sal marinho: </strong>antibacteriano, o sal marinho
                ajuda na esfoliação combatendo a pele seca.
              </p>
            </div>
            <div className="img-no-pets">
              <img src="/images/ingredients/no-pets.png" alt="" />
            </div>
          </div>
          <div className="img">
            <picture>
              <source
                srcSet="/images/hero/madame-creme-desktop.png"
                media="(min-width: 990px)"
              />

              <source
                srcSet="/images/hero/madame-creme-tablet.png"
                media="(min-width: 430px)"
              />

              <img
                src="/images/hero/madame-creme-mobile.png"
                alt="Madame Crème"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ingredients
