import { useState, useEffect } from "react"
import debounce from "utils/debounce"

function useResponsive({
  mobile = 500,
  tablet = 800,
  below = 400,
  above = 1080,
} = {}) {
  const [windowWidth, setWindowWidth] = useState(mobile)

  const debouncedUpdateSize = debounce(updateSize, 200)

  function updateSize() {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", debouncedUpdateSize)
    updateSize()

    return () => window.removeEventListener("resize", debouncedUpdateSize)
  }, [])

  const isMobile = windowWidth <= mobile
  const isTablet = !isMobile && windowWidth <= tablet
  const isDesktop = windowWidth > tablet

  const isAbove = windowWidth > above
  const isBelow = windowWidth < below

  return {
    isMobile,
    isTablet,
    isDesktop,
    isBelow,
    isAbove,
    windowWidth,
  }
}

export default useResponsive
