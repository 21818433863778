import "./Carousel.scss"
import Arrow from "components/Arrow"
import ReactCarousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

const withArrowProps = {
  arrowLeft: <Arrow direction="left" />,
  arrowLeftDisabled: <div />,
  arrowRight: <Arrow direction="right" />,
  arrowRightDisabled: <div />,
  addArrowClickHandler: true,
}

const withoutArrowsProps = {
  arrows: false,
}

function Carousel({
  hideArrows = false,
  hideDots = false,
  children,
  ...props
}) {
  const arrowsProps = hideArrows ? withoutArrowsProps : withArrowProps
  return (
    <ReactCarousel {...arrowsProps} dots={!hideDots} {...props}>
      {children}
    </ReactCarousel>
  )
}

export default Carousel
