import Nav from "./components/Nav"
import BuySection from "./components/BuySection"

import "./Hero.scss"
import Arrow from "components/Arrow"

function Hero() {
  return (
    <div className="hero-container">
      <div className="hero-bg"></div>
      <div className="container background-container -hidden">
        <div className="hero-image">
          <img src="/images/hero/woman-body.png" alt="corpo mulher" />
        </div>
      </div>
      <div className="container background-container">
        <picture className="creme-image">
          <source
            srcSet="/images/hero/madame-creme-desktop.png"
            media="(min-width: 990px)"
          />

          <source
            srcSet="/images/hero/madame-creme-tablet.png"
            media="(min-width: 680px)"
          />

          <img src="/images/hero/madame-creme-mobile.png" alt="Madame Crème" />
        </picture>
      </div>
      <div className="hero-content container">
        <Nav />
        <BuySection />
      </div>
      <div className="square">
        <div className="square-content">
          <Arrow href="#benefits" />
        </div>
      </div>
    </div>
  )
}

export default Hero
