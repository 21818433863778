import Hero from "./components/Hero"
import Benefits from "./components/Benefits"
import Depositions from "./components/Depositions"
import Use from "./components/Use"
import Tips from "./components/Tips"
import ButtonBuy from "./components/ButtonBuy"
import Ingredients from "./components/Ingredients"
import Footer from "./components/Footer"

import Layout from "components/Layout"

const HomePage = () => {
  return (
    <Layout>
      <Hero />

      <Benefits />

      <Depositions />

      <Use />

      <Tips />

      <ButtonBuy />

      <Ingredients />

      <Footer />
    </Layout>
  )
}

export default HomePage
