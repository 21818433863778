import Courosel from "components/Carousel"

import useResponsive from "hooks/useResponsive"

import "./Tips.scss"

function Tips() {
  const { isBelow } = useResponsive({ below: 560 })

  return (
    <div className="container-tips">
      <div className="bg-tips"></div>
      <div className="content container">
        <div className="content-title-video">
          <div className="content-title">
            <div className="bg-title"></div>
            <h3 className="title">dicas de quem usa</h3>
            <div className="text">
              <p className="name">Vic Ceridono no vídeo</p>
              <p className="question">vamos falar sobre esfoliação corporal?</p>
            </div>
            <div className="bg-video"></div>
          </div>

          <div className="video">
            <video
              width="282"
              height="499"
              controls
              disablePictureInPicture
              controlsList="nodownload"
              poster="/images/tips/vic-ceridono-poster.png"
            >
              <source src="/video/tips/vic-ceridono.mp4" type="video/mp4" />
              Vídeo não suportado.
            </video>
          </div>
        </div>
        {isBelow ? (
          <div className="courosel">
            <Courosel hideArrows={true}>
              <div className="image">
                <img
                  src="/images/tips/1.png"
                  alt="Foto de uma mulher no banho"
                />
              </div>
              <div className="image">
                <img src="/images/tips/2.png" alt="Foto de uma mulher" />
              </div>
              <div className="image">
                <img
                  src="/images/tips/3.png"
                  alt="Foto de uma mulher com celular"
                />
              </div>
            </Courosel>
          </div>
        ) : (
          <div className="content-images">
            <div className="bg-images"></div>
            <div className="images">
              <img
                className="img-1"
                src="/images/tips/1.png"
                alt="Foto de uma mulher no banho"
              />
              <img
                className="img-2"
                src="/images/tips/2.png"
                alt="Foto de uma mulher"
              />
              <img
                className="img-3"
                src="/images/tips/3.png"
                alt="Foto de uma mulher com celular"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Tips
