import Carousel from "components/Carousel"

import "./Depositions.scss"
import useResponsive from "hooks/useResponsive"

function Depositions() {
  const { isMobile } = useResponsive({ below: 300 })

  const hideArrows = isMobile
  const hideDots = !hideArrows

  return (
    <div className="container-depositions" id="depositions">
      <div className="bg-container"></div>
      <div className="content container">
        <div className="content-title">
          <div className="bg-title"></div>
          <h3 className="title">depoimentos</h3>
        </div>
        <Carousel hideArrows={hideArrows} hideDots={hideDots}>
          <div id="item-1">
            <div className="content-text">
              <div className="bg-text">
                <img src="/icons/depositions/quote-icon.png" alt="aspas" />
              </div>
              <p className="text">
                "que produto maravilhoso, deixou minha pele muito mais hidratada
                e meu bumbum sem celulites"
              </p>

              <div className="box-name">
                <span className="name">Rosinante Vole</span>
                <span className="office">SEO Marketer</span>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  )
}

export default Depositions
