import "./Button.scss"

function Button({ text, href }) {
  return (
    <a className="btn" href="https://pag.ae/7W3b5L9jt" target="_blank">
      {text}
    </a>
  )
}

export default Button
