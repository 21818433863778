import "./Nav.scss"

function Nav() {
  return (
    <div className="container-nav">
      <div className="nav-image">
        <img className="img-logo" src="/images/logo.png" alt="logo" />
      </div>
      <ul className="nav-items">
        <li className="item">
          <a href="#benefits">beneficíos</a>
        </li>
        <li className="item">
          <a href="#depositions">depoimentos</a>
        </li>
        <li className="item">
          <a href="#use">como usar</a>
        </li>
        <li className="item">
          <a href="#ingredients">ingredientes</a>
        </li>
      </ul>
    </div>
  )
}

export default Nav
