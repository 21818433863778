import "./Footer.scss"

function Footer() {
  return (
    <div className="container-footer">
      <div className="content container">
        <div className="content-header">
          <div className="line horizontal hide-mobile"></div>
          <img src="/images/logo.png" alt="logo" />
          <div className="line horizontal hide-mobile"></div>
        </div>
        <div className="content-body hide-mobile">
          <div className="content-text">
            <span className="title">precauções e advertências</span>
            <p className="text">
              Uso externo. Mantenha fora do alcance das crianças. Conserve em
              temperatura ambiente até 30ºC. Produto alergênico por conter
              vestígios de amêndoas como ingrediente. Em caso de contato com os
              olhos, enxaguar abundantemente com água.
            </p>
          </div>
          <div className="content-social">
            <div className="icons-social">
              <img src="/icons/footer/icn-facebook.png" alt="Facebook" />
              <img src="/icons/footer/icn-instagram.png" alt="Instagram" />
              <img src="/icons/footer/icn-youtube.png" alt="Youtube" />
            </div>
            <div className="line vertical"></div>
          </div>
          <div className="content-payment">
            <span className="title">formas de pagamento</span>
            <div className="image-payments">
              <img src="/icons/footer/icn-card.png" alt="formas de pagamento" />
            </div>
          </div>
        </div>

        <div className="content-body hide-desktop">
          <div className="content-social">
            <div className="icons-social">
              <img src="/icons/footer/icn-facebook.png" alt="Facebook" />
              <img src="/icons/footer/icn-instagram.png" alt="Instagram" />
              <img src="/icons/footer/icn-youtube.png" alt="Youtube" />
            </div>
          </div>

          <div className="content-text">
            <span className="title">precauções e advertências</span>
            <p className="text">
              Uso externo. Mantenha fora do alcance das crianças. Conserve em
              temperatura ambiente até 30ºC. Produto alergênico por conter
              vestígios de amêndoas como ingrediente. Em caso de contato com os
              olhos, enxaguar abundantemente com água.
            </p>
          </div>

          <div className="content-payment">
            <span className="title">formas de pagamento</span>
            <div className="image-payments">
              <img src="/icons/footer/icn-card.png" alt="formas de pagamento" />
            </div>
          </div>
          <div className="line vertical"></div>
        </div>
        <div className="content-info">
          <span>Copyright &copy; 2020 madamecrème. All Rights Reserved.</span>
        </div>
      </div>
    </div>
  )
}

export default Footer
