import "./Use.scss"

function Use() {
  return (
    <div className="container-use" id="use">
      <div className="content container">
        <div className="bg-body">
          <img src="/images/use/body.png" alt="corpo de mulher" />
        </div>
        <div className="content-text start">
          <div className="bg-text"></div>
          <h3 className="title">como usar</h3>
          <p className="text">
            entenda como o produto funciona e como vc pode usa-lo no seu
            dia-a-dia.
          </p>
        </div>
        <div className="content-carousel">
          <img src="/images/use/use.png" alt="passo a passo do uso" />
        </div>
        <div className="content-text center">
          <h3 className="title">pronto!</h3>
          <p className="text">
            agora você tem um bumbum mais firme e uma pele hidratada.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Use
