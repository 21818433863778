import Button from "components/Button"

import "./BuySection.scss"

function BuySection() {
  return (
    <div className="container-buy">
      <div className="square-content-info"></div>
      <div className="content">
        <h3 className="title">bem-vinda ao clube dos bumbuns hidratados</h3>
        <h2 className="content-subtitle">almond & carrot express scrumb</h2>
        <p className="content-text">
          Um esfoliante para todos os tipos de pele inclusive, pele secas e
          irritadas, com estrias ou cicatrizes.
        </p>
        <div className="content-btn-buy">
          <div className="info-value">
            <span className="label-value">apenas</span>
            <span className="value">R$ 116</span>
          </div>
          <Button text={"comprar"} />
        </div>
      </div>
    </div>
  )
}

export default BuySection
